import { Stack, TableCell } from '@mui/material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { useSearchParams } from 'react-router-dom';
import { FinalisationFormDocument, finalisationFormQuery } from '../../../api/document';
import {
    deleteFinalisationFormReplyMutation,
    uploadFinlisationFormReply,
} from '../../../api/formsAndIndemnities';
import { queryClient } from '../../../api/queryClient';
import DateTimeString from '../../../components/DateTimeString';
import DeleteDocumentDialog from '../../../components/DeleteDocumentDialog';
import FileLink from '../../../components/FileLink';
import Placeholder from '../../../components/Placeholder';
import QueryProgress from '../../../components/QueryProgress';
import SearchParamSelect, { SearchParamSelectOption } from '../../../components/SearchParamSelect';
import UploadButton from '../../../components/UploadButton';
import UploadDropArea from '../../../components/UploadDropArea';
import GenericTable, { Column } from '../../../components/table/genericTable';
import { useStore } from '../../../store/storeContext';
import { invalidateWorkspace } from '../../../utils';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

const columns: Column<FinalisationFormDocument>[] = [
    {
        id: 'participant',
        label: 'Participant',
        Component: ({ object: form }) => (
            <TableCell>
                {form?.uploaded_by.organisation_external_display_name ||
                    form?.uploaded_by.organisation_name}
            </TableCell>
        ),
    },
    {
        id: 'sector',
        label: 'Sector',
        Component: ({ object: form }) => (
            <TableCell>{form?.uploaded_by.organisation_industry}</TableCell>
        ),
    },
    {
        id: 'template',
        label: 'Template',
        Component: ({ object: form }) => (
            <TableCell>
                <FileLink file_name={form.file_name || ''} file={form.file || ''} maxChars={20} />
            </TableCell>
        ),
    },
    {
        id: 'uploaded',
        label: 'Uploaded',
        Component: ({ object: form }) => <TableCell>{DateTimeString(form.created_at)}</TableCell>,
    },
    {
        id: 'uploaded_by',
        label: 'Uploaded by',
        Component: ({ object: form }) => <TableCell>{form.uploaded_by.name}</TableCell>,
    },
    {
        id: 'response',
        label: 'Response',
        Component: ({ object: form }) => <ResponseCell form={form} />,
    },
];

function ResponseCell({ form }: { form: FinalisationFormDocument }) {
    const store = useStore();
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const [searchParams] = useSearchParams();
    const membershipParam = searchParams.get('membership') || '';

    const onUpload = (file: File) =>
        store.uploadDocument(
            file,
            (file: File) => uploadFinlisationFormReply(workspace.id, form.id, file),
            () => {
                invalidateWorkspace(workspace.id);
                queryClient.invalidateQueries(
                    finalisationFormQuery(workspace.id, { membership: membershipParam })
                );
            }
        );

    const deleteMutation = useMutation(deleteFinalisationFormReplyMutation());

    const handleDelete = () => {
        deleteMutation.mutate(form.reply!.id, {
            onSuccess: () => {
                invalidateWorkspace(workspace.id);
                queryClient.invalidateQueries(
                    finalisationFormQuery(workspace.id, { membership: membershipParam })
                );
            },
        });
    };

    return (
        <TableCell>
            {form.reply ? (
                <FileLink
                    file_name={form.reply.file_name || ''}
                    file={form.reply.file || ''}
                    maxChars={20}
                    onDelete={() => {
                        showModal(DeleteDocumentDialog, {
                            onDelete: handleDelete,
                        });
                    }}
                />
            ) : (
                <UploadDropArea onUpload={onUpload}>
                    <Stack direction="row" gap={2} alignItems="center">
                        <UploadButton
                            onUpload={onUpload}
                            variant="inverse"
                            sx={{ whiteSpace: 'nowrap' }}
                        >
                            Upload completed form
                        </UploadButton>
                    </Stack>
                </UploadDropArea>
            )}
        </TableCell>
    );
}

function AccountInstructions() {
    const workspace = useWorkspace();

    const [searchParams] = useSearchParams();
    const membershipParam = searchParams.get('membership') || '';

    const query = useQuery({
        ...finalisationFormQuery(workspace.id, { membership: membershipParam }),
        placeholderData: (previousData, _) => previousData,
    });

    const memberFilterOptions: SearchParamSelectOption[] =
        query.data?.results
            .filter((result) => !!result.membership)
            .map((result) => ({
                id: result.membership || '',
                label:
                    result.uploaded_by.organisation_external_display_name ||
                    result.uploaded_by.organisation_name ||
                    '',
            }))
            .sort((org1, org2) => org1.label.localeCompare(org2.label))
            .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)) ||
        [];

    return (
        <PageLayout title="Account Instructions">
            <Stack sx={{ mt: -1 }} flexGrow={1}>
                <QueryProgress query={query}>
                    <GenericTable
                        defaultSort="created_at"
                        objects={query.data?.results || []}
                        columns={columns}
                        placeholder={
                            <Placeholder
                                title="No files yet"
                                description="Account Instructions uploaded by Workspace Participants will appear here"
                            />
                        }
                        actions={
                            <SearchParamSelect
                                parameter={'membership'}
                                options={memberFilterOptions}
                                placeholder="Filter by Participant"
                            />
                        }
                    />
                </QueryProgress>
            </Stack>
        </PageLayout>
    );
}

export default AccountInstructions;
