import { LoadingButton } from '@mui/lab';
import { Grid, Stack, Typography } from '@mui/material';
import { IconArrowRight } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { enqueueSnackbar } from 'notistack';
import { DatePickerElement, FormContainer, SelectElement, useForm } from 'react-hook-form-mui';
import { useNavigate } from 'react-router-dom';
import { deceasedDetailQuery, updateDeceasedDetail } from '../../../../api/deceasedDetail';
import { components } from '../../../../api/schema';
import { dateString } from '../../../../api/utils';
import FormGrid from '../../../../components/FormGrid';
import QueryProgress from '../../../../components/QueryProgress';
import CountryPicker from '../../../../components/fields/CountryAutocompleteField';
import { nullableBooleanToYesNo } from '../../../../utils';
import PageLayout from '../../PageLayout';
import { useWorkspace } from '../../workspaceContext';
import DeathCertificateFields, {
    FormValues as DeathCertificateFormValues,
} from './DeathCertificateFields';
import OtherProofofDeathFields, {
    FormValues as OtherProofOfDeathFormValues,
} from './OtherProofofDeathFields';

function ProofOfDeath() {
    return (
        <PageLayout title="Proof of Death">
            <ProofOfDeathContent />
        </PageLayout>
    );
}

export interface FormValues extends DeathCertificateFormValues, OtherProofOfDeathFormValues {
    date_of_death: Date | undefined;
    place_of_death: components['schemas']['PlaceOfDeathEnum'] | undefined;
    place_of_death_country: string;
}

export function ProofOfDeathContent() {
    const workspace = useWorkspace();
    const navigate = useNavigate();

    const query = useQuery(deceasedDetailQuery(workspace.deceased_detail.id));
    const details = query.data;

    const updateMutation = useMutation(updateDeceasedDetail());

    const onSubmit = (values: FormValues) => {
        updateMutation.mutate(
            {
                id: details?.id,
                date_of_death: dateString(values.date_of_death),
                place_of_death: values.place_of_death,
                place_of_death_overseas: values.place_of_death_country,
                has_aus_death_cert:
                    values.has_aus_death_cert === 'yes' || !!workspace.death_certificate,
                no_death_cert_reason: values.no_death_cert_reason,
                has_other_pod:
                    values.other_proof_of_death_held === 'yes' || locationOfDeath === 'overseas',
            },
            {
                onSuccess: () => {
                    enqueueSnackbar('Changes saved.', { variant: 'success' });
                    navigate(`/workspace/${workspace.id}/poa-data-entry`);
                },
            }
        );
    };

    const formMethods = useForm<FormValues>({
        mode: 'onChange',
        shouldUnregister: true,
        defaultValues: {
            date_of_death: details?.date_of_death ? parseISO(details.date_of_death) : undefined,
            place_of_death: details?.place_of_death || undefined,
            place_of_death_country: details?.place_of_death_overseas || '',
            has_aus_death_cert: nullableBooleanToYesNo(details?.has_aus_death_cert),
            no_death_cert_reason: details?.no_death_cert_reason || '',
            other_proof_of_death_held: nullableBooleanToYesNo(details?.has_other_pod),
            death_cert: '',
            other_proof_of_death: '',
        },
    });

    const locationOfDeath = formMethods.watch('place_of_death');

    const { errors } = formMethods.formState;

    return (
        <QueryProgress query={query}>
            <FormContainer formContext={formMethods} onSuccess={onSubmit}>
                <Stack gap={5} sx={{ mt: 4 }}>
                    <FormGrid sx={{ mb: -3 }}>
                        <Grid item xs={6}>
                            <DatePickerElement
                                name="date_of_death"
                                label="Date of death"
                                required
                                maxDate={new Date()}
                                sx={{ width: '100%' }}
                            />
                        </Grid>
                        <Grid item xs={6} />

                        <Grid item xs={6}>
                            <SelectElement
                                name="place_of_death"
                                label="Location of death"
                                required
                                fullWidth
                                options={[
                                    { id: 1, label: 'ACT', value: 'ACT' },
                                    { id: 2, label: 'NSW', value: 'NSW' },
                                    { id: 3, label: 'NT', value: 'NT' },
                                    { id: 4, label: 'QLD', value: 'QLD' },
                                    { id: 5, label: 'SA', value: 'SA' },
                                    { id: 6, label: 'TAS', value: 'TAS' },
                                    { id: 7, label: 'VIC', value: 'VIC' },
                                    { id: 8, label: 'WA', value: 'WA' },
                                    {
                                        id: 9,
                                        label: 'Outside of Australia',
                                        value: 'overseas',
                                    },
                                ]}
                                valueKey="value"
                            />
                        </Grid>

                        {locationOfDeath === 'overseas' ? (
                            <Grid item xs={6}>
                                <CountryPicker
                                    name="place_of_death_country"
                                    label="Country of Death"
                                    required
                                />
                            </Grid>
                        ) : null}
                    </FormGrid>

                    {locationOfDeath && locationOfDeath !== 'overseas' && (
                        <DeathCertificateFields workspace={workspace} />
                    )}

                    {locationOfDeath && (
                        <OtherProofofDeathFields
                            workspace={workspace}
                            required={locationOfDeath === 'overseas'}
                        />
                    )}

                    <Stack
                        direction="row"
                        justifyContent="flex-end"
                        sx={{
                            bottom: 0,
                            zIndex: 3,
                        }}
                    >
                        {errors.root?.message && (
                            <Typography color="error">{errors.root.message}</Typography>
                        )}
                        <LoadingButton
                            variant="contained"
                            type="submit"
                            size="large"
                            startIcon={<IconArrowRight />}
                        >
                            Next step
                        </LoadingButton>
                    </Stack>
                </Stack>
            </FormContainer>
        </QueryProgress>
    );
}

export default ProofOfDeath;
