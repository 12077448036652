import {
    Alert,
    Badge,
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from '@mui/material';
import { IconArrowRight, IconMessageDots, IconOutbound } from '@tabler/icons-react';
import { useQuery } from '@tanstack/react-query';
import { Link, useNavigate } from 'react-router-dom';

import { useUpdateEffect } from '@react-hookz/web';
import { useModal } from 'mui-modal-provider';
import { queryClient } from '../../../../api/queryClient';
import { workspaceQuery } from '../../../../api/workspace';
import {
    WorkspaceMembership,
    WorkspaceMembershipsRequest,
    workspaceMembershipsQuery,
} from '../../../../api/workspaceMembership';
import DateTimeString from '../../../../components/DateTimeString';
import TableSortLabel from '../../../../components/TableSortLabel';
import TaskIcon, { TaskStatusEnum } from '../../../../components/TaskIcon';
import TruncatedTableCell from '../../../../components/TruncatedTableCell';
import WorkspaceReference from '../../../../components/WorkspaceReference';
import useSort from '../../../../hooks/useSort';
import { colors } from '../../../../theme/palette';
import PageLayout from '../../PageLayout';
import { useWorkspace } from '../../workspaceContext';
import AddParticipantDialog from './AddParticipantDialog';

function Participants() {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    // Ensure workspace.is_data_entry_complete is always up to date by invalidating the workspace
    // query on first render
    useUpdateEffect(() => {
        queryClient.invalidateQueries(workspaceQuery(workspace.id));
    });

    return (
        <PageLayout
            title="Summary"
            actions={
                workspace.is_data_entry_complete ? (
                    <Button variant="contained" onClick={() => showModal(AddParticipantDialog)}>
                        Invite Participant
                    </Button>
                ) : null
            }
        >
            <Paper
                sx={{
                    borderRadius: 4,
                    p: 4,
                    py: 2.5,
                    mt: 4,
                    mb: 3,
                    backgroundColor: 'background.default',
                }}
            >
                <Typography component="div">
                    <Grid container spacing={1} alignItems="center">
                        <Grid item xs={2} sx={{ fontWeight: 400 }}>
                            Your reference:
                        </Grid>
                        <Grid item xs={4}>
                            <Box sx={{ py: 1, height: 2, alignItems: 'center' }}>
                                <WorkspaceReference />
                            </Box>
                        </Grid>
                        <Grid item xs={2} sx={{ fontWeight: 400 }}>
                            Created:
                        </Grid>
                        <Grid item xs={4}>
                            {DateTimeString(workspace.created_at)}
                        </Grid>
                    </Grid>
                </Typography>
            </Paper>
            {workspace.is_data_entry_complete ? (
                <ParticipantsTable />
            ) : (
                <Alert
                    severity="info"
                    sx={{
                        alignItems: 'center',
                    }}
                    action={
                        <Button
                            startIcon={<IconArrowRight />}
                            variant="outlined"
                            color="inherit"
                            component={Link}
                            to={`deceased-information`}
                        >
                            Go
                        </Button>
                    }
                >
                    Please complete Estate Details section before inviting Participants to the
                    Workspace
                </Alert>
            )}
        </PageLayout>
    );
}

function ParticipantsTable() {
    const workspace = useWorkspace();

    const defaultSort = '-created_at';
    const { sortParam, sortColumn, sortDirection, sortLink } = useSort(defaultSort);

    const isActive = (column: string) => sortColumn === column;

    const membersQueryOptions: WorkspaceMembershipsRequest = {
        workspace: workspace.id,
        ordering: sortParam,
    };

    const members = useQuery({
        ...workspaceMembershipsQuery(membersQueryOptions),
        placeholderData: (previousData, previousQuery) => previousData,
    });

    return (
        <TableContainer>
            <Table
                className="table-soft"
                sx={{
                    whiteSpace: 'nowrap',
                }}
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <TableSortLabel
                                label="Participant"
                                sortLink={sortLink('organisation_name')}
                                sortDirection={sortDirection}
                                isActive={isActive('organisation_name')}
                            />
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                label="Sector"
                                sortLink={sortLink('industry_code_label')}
                                sortDirection={sortDirection}
                                isActive={isActive('industry_code_label')}
                            />
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                label="Invited"
                                sortLink={sortLink('invited_at')}
                                sortDirection={sortDirection}
                                isActive={isActive('invited_at')}
                            />
                        </TableCell>
                        <TableCell>
                            <TableSortLabel
                                label="Status"
                                sortLink={sortLink('status_label')}
                                sortDirection={sortDirection}
                                isActive={isActive('status_label')}
                            />
                        </TableCell>
                        <TableCell style={{ textAlign: 'center', lineHeight: '1.3' }}>
                            Statement of <br /> Position
                        </TableCell>
                        <TableCell style={{ textAlign: 'center', lineHeight: '1.3' }}>
                            Account <br />
                            Instructions
                        </TableCell>
                        <TableCell style={{ textAlign: 'center', lineHeight: '1.3' }}>
                            Account <br />
                            Finalisation
                        </TableCell>
                        <TableCell sx={{ width: '3.5rem' }} />
                    </TableRow>
                </TableHead>

                <TableBody>
                    {members.data?.map((participant) => (
                        <ParticipantRow key={participant.id} participant={participant} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

interface CheckIconWithTimeProps {
    offPlatform?: boolean;
    status?: TaskStatusEnum;
    finishedAt?: string;
}

function CheckIconWithTime({ status, offPlatform, finishedAt }: CheckIconWithTimeProps) {
    const done = `Completed at ${DateTimeString(finishedAt)}`;
    return (
        <>
            {offPlatform ? (
                <Tooltip title="Shared off platform">
                    <Box
                        sx={{
                            color: colors.green,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 24,
                            height: 24,
                        }}
                    >
                        {<IconOutbound size={24} />}
                    </Box>
                </Tooltip>
            ) : (
                <TaskIcon
                    status={status ?? 'not_started'}
                    tooltip={finishedAt ? done : 'Incomplete'}
                />
            )}
        </>
    );
}

interface ParticipantsRowProps {
    participant: WorkspaceMembership;
}

function ParticipantRow({ participant }: ParticipantsRowProps) {
    const workspace = useWorkspace();
    const navigate = useNavigate();

    const toDetails = () => navigate(`/workspace/${workspace.id}/participants/${participant.id}`);
    const toSoP = () =>
        navigate(`/workspace/${workspace.id}/statement-of-position?membership=${participant.id}`);
    const toForms = () =>
        navigate(`/workspace/${workspace.id}/account-instructions?membership=${participant.id}`);
    const toFinalisation = () =>
        navigate(`/workspace/${workspace.id}/finalisation?membership=${participant.id}`);
    const toRequest = () =>
        navigate(
            `/workspace/${workspace.id}/requests?organisation=${participant.organisation}&status=open`
        );

    return (
        <TableRow key={participant.id} hover style={{ cursor: 'pointer' }} onClick={toDetails}>
            <TruncatedTableCell maxWidth="20ch">{participant.organisation_name}</TruncatedTableCell>
            <TableCell>{participant.industry_code_label}</TableCell>
            <TableCell>{DateTimeString(participant.invited_at)}</TableCell>

            <TableCell onClick={toDetails}>{participant.status_label}</TableCell>

            <TableCell
                onClick={(e) => {
                    e.stopPropagation();
                    toSoP();
                }}
            >
                <Stack justifyContent="space-around" direction="row">
                    <CheckIconWithTime
                        offPlatform={
                            participant.statement_of_position_task?.docs_shared_off_platform
                        }
                        status={participant.statement_of_position_task?.status}
                        finishedAt={
                            participant.statement_of_position_task?.completed_at || undefined
                        }
                    />
                </Stack>
            </TableCell>

            <TableCell
                onClick={(e) => {
                    e.stopPropagation();
                    toForms();
                }}
            >
                <Stack justifyContent="space-around" direction="row">
                    <CheckIconWithTime
                        offPlatform={
                            participant.forms_and_indemnities_task?.docs_shared_off_platform
                        }
                        status={participant.forms_and_indemnities_task?.status}
                        finishedAt={
                            participant.forms_and_indemnities_task?.completed_at || undefined
                        }
                    />
                </Stack>
            </TableCell>

            <TableCell
                onClick={(e) => {
                    e.stopPropagation();
                    toFinalisation();
                }}
            >
                <Stack justifyContent="space-around" direction="row">
                    <CheckIconWithTime
                        offPlatform={participant.estate_finalisation_task?.docs_shared_off_platform}
                        status={participant.estate_finalisation_task?.status}
                        finishedAt={participant.estate_finalisation_task?.completed_at || undefined}
                    />
                </Stack>
            </TableCell>

            <TableCell
                onClick={(e) => {
                    e.stopPropagation();
                    toRequest();
                }}
            >
                <Badge badgeContent={participant.open_requests_count} color="secondary">
                    <IconButton size="small">
                        <IconMessageDots />
                    </IconButton>
                </Badge>
            </TableCell>
        </TableRow>
    );
}

export default Participants;
