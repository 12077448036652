import ModalProvider from 'mui-modal-provider';
import { Outlet, RouteObject, createBrowserRouter, redirect } from 'react-router-dom';
import { logout } from '../api/user';
import Error from '../components/Error';
import ProgressBar from '../components/ProgressBar';
import AdminUserError from '../pages/auth/AdminUserError';
import ConsentForm from '../pages/main/ConsentForm';
import authRoutes from './authRoutes';
import mainRoutes from './mainRoutes';

export const router = createBrowserRouter(
    [
        {
            path: '/',
            element: (
                <ModalProvider>
                    <ProgressBar />
                    <Outlet />
                </ModalProvider>
            ),
            errorElement: <Error />,
            children: [
                authRoutes,
                {
                    path: '/consent',
                    element: <ConsentForm />,
                },
                {
                    path: '/admin-user',
                    element: <AdminUserError />,
                },
                {
                    path: 'logout',
                    action: async () => {
                        await logout();
                        return redirect('/auth/login');
                    },
                },
                mainRoutes,
            ],
        },
    ],
    { basename: '/' }
);

// Extract all routes from the router
const extractPaths = (routes: RouteObject[], basePath = ''): string[] => {
    const paths: string[] = [];

    routes.forEach((route) => {
        if (route.path) {
            if (!basePath.endsWith('/') && !route.path.startsWith('/')) {
                basePath += '/';
            }
            const fullPath = `${basePath}${route.path}`.replace(/\/\/+/g, '/'); // Normalize slashes
            paths.push(fullPath);
        }

        if (route.children) {
            paths.push(...extractPaths(route.children, `${basePath}${route.path || ''}`));
        }
    });

    // Remove duplicates
    return [...new Set(paths)];
};

const allRoutes = extractPaths(router.routes);

// Create a hidden element to store the allRoutes variable
const hiddenElement = document.createElement('div');
hiddenElement.id = 'all-routes';
hiddenElement.style.display = 'none';
document.body.appendChild(hiddenElement);
hiddenElement.textContent = JSON.stringify(allRoutes);
