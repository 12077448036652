import { LoadingButton } from '@mui/lab';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    IconButton,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import { IconExternalLink } from '@tabler/icons-react';
import { useState } from 'react';
import { Controller, FormContainer, useForm } from 'react-hook-form-mui';

interface ShareDefaultDocumentsDialogProps extends Omit<DialogProps, 'onSubmit'> {
    onClose?: () => void;
    documents: {
        id: string;
        name: string;
        file: string;
    }[];
    onSubmit: (documentIds: string[]) => Promise<void>;
}

function ShareDefaultDocumentsDialog({
    onClose,
    title,
    documents,
    onSubmit,
    ...dialogProps
}: ShareDefaultDocumentsDialogProps) {
    const formMethods = useForm({
        defaultValues: {
            documents: documents.map((doc) => doc.id),
        },
    });

    const handleSubmit = async (data: any) => {
        await onSubmit(data.documents);
        onClose?.();
    };

    return (
        <Dialog onClose={onClose} {...dialogProps} maxWidth="sm" fullWidth>
            <DialogTitle>Share default documents</DialogTitle>

            <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                <DialogContent>
                    <FormLabel>Select documents to share</FormLabel>
                    <Controller
                        name="documents"
                        control={formMethods.control}
                        rules={{
                            validate: (value: string[]) =>
                                value.length > 0 || 'Please select at least one document',
                        }}
                        render={({ field, fieldState }) => (
                            <Stack>
                                {documents.map((doc, index) => (
                                    <FormControlLabel
                                        key={doc.id}
                                        label={<FileLabel name={doc.name} link={doc.file} />}
                                        sx={{
                                            padding: index === 0 ? 'default' : 0,
                                        }}
                                        control={
                                            <Checkbox
                                                {...field}
                                                checked={field.value.includes(doc.id)}
                                                onChange={(e) => {
                                                    const newValue = e.target.checked
                                                        ? [...field.value, doc.id]
                                                        : field.value.filter((id) => id !== doc.id);
                                                    field.onChange(newValue);
                                                }}
                                            />
                                        }
                                    />
                                ))}
                                {fieldState.error && (
                                    <FormHelperText error>
                                        {fieldState.error.message}
                                    </FormHelperText>
                                )}
                            </Stack>
                        )}
                    />
                </DialogContent>

                <DialogActions>
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={formMethods.formState.isSubmitting}
                    >
                        Confirm
                    </LoadingButton>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

export const FileLabel = ({ name, link }: { name: string; link: string }) => {
    const [hover, setHover] = useState(false);
    const [focused, setFocused] = useState(false);
    return (
        <Stack
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            direction="row"
            alignItems="center"
            gap={0.5}
        >
            <Typography variant="body2" sx={{ lineHeight: 1 }}>
                {name}
            </Typography>
            <Tooltip title="Open in new tab">
                <IconButton
                    component="a"
                    href={link}
                    target="_blank"
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    size="small"
                    tabIndex={0}
                    sx={{
                        opacity: hover || focused ? 1 : 0,
                    }}
                >
                    <IconExternalLink size={20} />
                </IconButton>
            </Tooltip>
        </Stack>
    );
};

export default ShareDefaultDocumentsDialog;
