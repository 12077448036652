import {
    Box,
    Paper,
    Stack,
    Tab,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tabs,
} from '@mui/material';
import { UseQueryResult, useQuery } from '@tanstack/react-query';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { GenericDocument } from '../../../api/document';
import { listEstateFinalisationTasksQuery } from '../../../api/estateFinalisation';
import {
    PaginatedGenericDocumentListRequestParams,
    getGenericDocuments,
} from '../../../api/genericDocument';
import DateTimeString from '../../../components/DateTimeString';
import Placeholder from '../../../components/Placeholder';
import QueryProgress from '../../../components/QueryProgress';
import SearchParamSelect, { SearchParamSelectOption } from '../../../components/SearchParamSelect';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import {
    DocumentTableRowCreatedAt,
    DocumentTableRowFile,
    DocumentTableRowUploadedByIndustry,
    DocumentTableRowUploadedByOrganisation,
    DocumentTableRowUploadedByUser,
} from '../../../components/documentsTable/DocumentsTableCells';
import useSort from '../../../hooks/useSort';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';

export default function Finalisation() {
    const [searchParams] = useSearchParams();
    const workspace = useWorkspace();

    const tab = searchParams.get('tab') || 'all';
    function tabLink(tab?: string) {
        const params = new URLSearchParams(searchParams);
        tab ? params.set('tab', tab) : params.delete('tab');
        return `?${params}`;
    }

    const membershipParam = searchParams.get('membership') || undefined;
    const defaultSort = '-created_at';
    const { sortParam } = useSort(defaultSort);

    const queryOptions: PaginatedGenericDocumentListRequestParams = {
        membership: membershipParam,
        workspace: workspace.id,
        ordering: sortParam,
    };

    const query = useQuery({
        ...getGenericDocuments('estate_finalisation', queryOptions),
        // Prevent the data in the table from disappearing when the query options change
        placeholderData: (previousData, _) => previousData,
    });
    const documents = query.data?.results || [];

    const memberFilterOptions: SearchParamSelectOption[] =
        query.data?.results
            .filter((result) => !!result.membership)
            .map((result) => ({
                id: result.membership || '',
                label:
                    result.uploaded_by.organisation_external_display_name ||
                    result.uploaded_by.organisation_name ||
                    '',
            }))
            .sort((org1, org2) => org1.label.localeCompare(org2.label))
            .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id)) ||
        [];

    return (
        <PageLayout title="Account Finalisation">
            <Stack direction="row" gap={3} alignItems="center" sx={{ mt: 2 }}>
                <Tabs value={tab} className="underlined">
                    <Tab
                        label={'Documents'}
                        value={'all'}
                        className="underlined"
                        component={RouterLink}
                        to={tabLink('all')}
                        state={{ keepPreviousData: 'false' }}
                    />
                    <Tab
                        label={'Shared off platform'}
                        value={'shared_off_platform'}
                        className="underlined"
                        component={RouterLink}
                        to={tabLink('shared_off_platform')}
                        state={{ keepPreviousData: 'false' }}
                    />
                </Tabs>
                <Box sx={{ flexGrow: 1 }} />
                {tab === 'all' && (
                    <SearchParamSelect
                        parameter={'membership'}
                        options={memberFilterOptions}
                        placeholder="Filter by Participant"
                    />
                )}
            </Stack>
            {tab === 'all' && <FinalisationDocumentsTable documents={documents} query={query} />}
            {tab === 'shared_off_platform' && <SharedOffPlatformTable />}
        </PageLayout>
    );
}

function FinalisationDocumentsTable({
    documents,
    query,
}: {
    documents: GenericDocument[];
    query: UseQueryResult;
}) {
    return (
        <Stack gap={2} flexGrow={1}>
            <DocumentsTable
                documents={documents}
                placeholder={
                    <Placeholder
                        title="No files yet"
                        description="Account Finalisation files uploaded by Workspace Participants will appear here"
                    />
                }
                columns={[
                    {
                        id: 'uploadedByOrganisation',
                        label: 'Participant',
                        Component: DocumentTableRowUploadedByOrganisation,
                    },
                    {
                        id: 'uploadedByIndustry',
                        label: 'Sector',
                        Component: DocumentTableRowUploadedByIndustry,
                    },
                    {
                        id: 'file',
                        label: 'File',
                        Component: DocumentTableRowFile,
                    },
                    {
                        id: 'createdAt',
                        label: 'Uploaded at',
                        sortable: 'created_at',
                        Component: DocumentTableRowCreatedAt,
                    },
                    {
                        id: 'uploadedByUser',
                        label: 'Uploaded by',
                        sortable: 'uploaded_by',
                        Component: DocumentTableRowUploadedByUser,
                    },
                ]}
            />
        </Stack>
    );
}

function SharedOffPlatformTable() {
    const workspace = useWorkspace();
    const query = useQuery(
        listEstateFinalisationTasksQuery({
            workspace: workspace.id,
            shared_off_platform: true,
        })
    );

    const tasks = query.data?.results || [];

    return (
        <QueryProgress query={query}>
            <TableContainer component={Paper} sx={{ height: '100%', overflowX: 'initial' }}>
                <Table className="table-soft" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>Participant</TableCell>
                            <TableCell>Sector</TableCell>
                            <TableCell>Marked by</TableCell>
                            <TableCell>Marked at</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tasks.map((task) => (
                            <TableRow key={task.id}>
                                <TableCell>{task.participant_name}</TableCell>
                                <TableCell>{task.participant_industry_code_label}</TableCell>
                                <TableCell>{task.mark_shared_off_platform_by_name}</TableCell>
                                <TableCell>
                                    {DateTimeString(task.mark_shared_off_platform_at)}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                {tasks.length === 0 ? (
                    <Stack height="100%">
                        <Placeholder
                            title="Nothing shared off platform yet"
                            description="Participants who have shared documents off-platform will appear here"
                        />
                    </Stack>
                ) : null}
            </TableContainer>
        </QueryProgress>
    );
}
