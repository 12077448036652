import { Box, Button, FormHelperText, FormLabel, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { Controller, TextFieldElement, useFormContext } from 'react-hook-form-mui';
import { deleteDeathCertificateMutation } from '../../../../api/deathCertificate';
import { queryClient } from '../../../../api/queryClient';
import { WorkspaceDetail, workspaceQuery } from '../../../../api/workspace';
import DocumentsTable from '../../../../components/documentsTable/DocumentsTable';
import RadioButtonGroupField from '../../../../components/fields/RadioButtonGroupField';
import { ValidationMessages } from '../../../../validationRules';
import DuplicateDeathCertificateAlert from './DuplicateDeathCertificateAlert';
import DeathCertificateUploadDialog from './UploadDeathCertificateDialog';

export interface FormValues {
    has_aus_death_cert: string;
    no_death_cert_reason: string;
    death_cert: string;
}

function DeathCertificateFields({ workspace }: { workspace: WorkspaceDetail }) {
    const { watch } = useFormContext<FormValues>();

    const watchDeathCertHeld = watch('has_aus_death_cert');

    if (workspace.death_certificate) {
        return (
            <Stack gap={2}>
                <FormLabel>Death Certificate</FormLabel>
                <DeathCertificateTable workspace={workspace} />
            </Stack>
        );
    }

    return (
        <Stack gap={1}>
            <RadioButtonGroupField
                required
                name="has_aus_death_cert"
                label="Has an Australian Death Certificate been issued?"
                options={[
                    { id: 1, label: 'Yes', value: 'yes' },
                    { id: 2, label: 'No', value: 'no' },
                ]}
                valueKey="value"
            />

            {watchDeathCertHeld === 'yes' ? (
                <DeathCertificateUploadField workspace={workspace} />
            ) : watchDeathCertHeld === 'no' ? (
                <TextFieldElement
                    name="no_death_cert_reason"
                    label="Reason for no Death Certificate"
                    multiline
                    fullWidth
                    minRows={3}
                    required
                    sx={{ width: '50%' }}
                />
            ) : null}
        </Stack>
    );
}

function DeathCertificateUploadField({ workspace }: { workspace: WorkspaceDetail }) {
    const { showModal } = useModal();
    const { control } = useFormContext<FormValues>();

    return (
        <Controller
            control={control}
            name="death_cert"
            rules={{
                validate: () => {
                    if (!workspace.death_certificate) {
                        return ValidationMessages.REQUIRED_FILE;
                    }
                    return true;
                },
            }}
            render={({ fieldState: { error } }) => (
                <Box>
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() => showModal(DeathCertificateUploadDialog)}
                    >
                        Upload Death Certificate
                    </Button>
                    {error && <FormHelperText error>{`${error.message}`}</FormHelperText>}
                </Box>
            )}
        />
    );
}

function DeathCertificateTable({ workspace }: { workspace: WorkspaceDetail }) {
    const deleteMutation = useMutation(deleteDeathCertificateMutation());
    const handleDelete = (id: string) => {
        deleteMutation.mutate(id, {
            onSuccess: () => {
                queryClient.invalidateQueries(workspaceQuery(workspace.id));
            },
        });
    };

    if (!workspace.death_certificate) {
        return null;
    }

    return (
        <Stack gap={1} sx={{ width: '100%' }}>
            <DocumentsTable documents={[workspace.death_certificate]} onDelete={handleDelete} />
            <DuplicateDeathCertificateAlert workspace={workspace} />
        </Stack>
    );
}

export default DeathCertificateFields;
