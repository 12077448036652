import {
    Icon,
    IconEdit,
    IconFilePlus,
    IconMailCheck,
    IconMailX,
    IconShare,
    IconUserScan,
} from '@tabler/icons-react';
import { NotificationType } from '../../../api/notification';

export const notificationIcon: Record<NotificationType, Icon> = {
    participant_noti_letter_uploaded: IconFilePlus,
    will_uploaded: IconFilePlus,
    other_pod_uploaded: IconFilePlus,
    other_poa_uploaded: IconFilePlus,
    gor_uploaded: IconFilePlus,
    related_person_id_doc_uploaded: IconFilePlus,
    sop_uploaded: IconFilePlus,
    finalisation_doc_uploaded: IconFilePlus,
    account_instruction_doc_uploaded: IconFilePlus,
    account_instruction_reply_doc_uploaded: IconFilePlus,
    death_cert_uploaded: IconFilePlus,
    invite_accepted: IconMailCheck,
    invite_declined: IconMailX,
    e_voi_completed_prof_rep: IconUserScan,
    e_voi_completed_participant: IconUserScan,
    has_will_edited: IconEdit,
    will_additional_details_edited: IconEdit,
    gor_status_edited: IconEdit,
    related_person_role_edited: IconEdit,
    deceased_given_names_edited: IconEdit,
    deceased_family_name_edited: IconEdit,
    deceased_dob_edited: IconEdit,
    deceased_dod_edited: IconEdit,
    deceased_aliases_edited: IconEdit,
    sop_distributed_off_platform: IconShare,
    acc_finalisation_distributed_off_platform: IconShare,
};
