import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IconExternalLink, IconTrash } from '@tabler/icons-react';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface FileLinkProps {
    file_name: string;
    file: string;
    maxChars?: number;
    onDelete?: () => void;
}

function FileLink({ file_name, file, maxChars, onDelete }: FileLinkProps) {
    const [hover, setHover] = useState(false);

    return (
        <Stack
            component={RouterLink}
            target="_blank"
            direction="row"
            gap={0.5}
            to={file || ''}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            sx={{
                textDecoration: 'none',
                whiteSpace: 'nowrap',
                alignItems: 'center',
                color: 'inherit',
                mr: -1,
                '&:hover': { textDecoration: 'underline' },
            }}
        >
            <Typography
                variant="body2"
                sx={{
                    textDecoration: 'none',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    maxWidth: maxChars ? `${maxChars}ch` : 'none',
                    lineHeight: 1,
                }}
            >
                {file_name}
            </Typography>

            <Tooltip title="Open in new tab">
                <IconButton
                    size="small"
                    sx={{
                        visibility: hover ? 'visible' : 'hidden',
                    }}
                >
                    <IconExternalLink size={20} />
                </IconButton>
            </Tooltip>

            {onDelete ? (
                <Tooltip title="Delete response">
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.preventDefault();
                            onDelete();
                        }}
                        sx={{ visibility: hover ? 'visible' : 'hidden' }}
                    >
                        <IconTrash size={20} />
                    </IconButton>
                </Tooltip>
            ) : null}
        </Stack>
    );
}

export default FileLink;
