import { LoadingButton } from '@mui/lab';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    DialogTitle,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Stack,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Controller, FormContainer, useForm } from 'react-hook-form-mui';
import { uploadGenericDocumentMutation } from '../../../api/genericDocument';
import { FileLabel } from '../../../components/ShareDefaultDocumentsDialog';
import DragAndDropFileField from '../../../components/fields/DragAndDropFileField';
import { useStore } from '../../../store/storeContext';
import { useWorkspace } from '../workspaceContext';

interface FormValues {
    defaultDocuments: string[];
    customDocuments: File[];
}

interface UploadCorrespondenceDialogProps extends Omit<DialogProps, 'onSubmit'> {
    defaultDocuments: {
        id: string;
        name: string;
        file: string;
    }[];
    taskType: 'estate_finalisation' | 'statement_of_position';
    title?: string;
    onClose?: () => void;
    onSuccess?: () => void;
    uploadDefaultDocuments: (documentIds: string[]) => Promise<any>;
}

function UploadCorrespondenceDialog({
    onClose,
    defaultDocuments,
    taskType,
    title,
    onSuccess,
    uploadDefaultDocuments,
    ...dialogProps
}: UploadCorrespondenceDialogProps) {
    const workspace = useWorkspace();
    const store = useStore();

    const formMethods = useForm<FormValues>({
        defaultValues: {
            defaultDocuments: [],
            customDocuments: [],
        },
    });

    const uploadGenericMutation = useMutation(uploadGenericDocumentMutation());

    const handleSubmit = async (data: FormValues) => {
        if (data.defaultDocuments.length > 0) {
            await uploadDefaultDocuments(data.defaultDocuments);
            enqueueSnackbar('Document upload successful.', {
                variant: 'success',
                autoHideDuration: 4000,
            });
        }

        data.customDocuments.forEach((file) => {
            store.uploadDocument(
                file,
                (file: File) =>
                    uploadGenericMutation.mutateAsync({
                        workspace: workspace.id,
                        file,
                        documentType: taskType,
                        taskType: taskType,
                    }),
                () => {
                    enqueueSnackbar('Document upload successful.', {
                        variant: 'success',
                        autoHideDuration: 4000,
                    });
                    onSuccess?.();
                }
            );
        });

        onClose?.();
    };

    return (
        <Dialog onClose={onClose} {...dialogProps} maxWidth="md" fullWidth>
            <DialogTitle>{title || 'Share documents'}</DialogTitle>

            <FormContainer formContext={formMethods} onSuccess={handleSubmit}>
                <DialogContent>
                    <Stack spacing={4}>
                        {defaultDocuments.length > 0 ? (
                            <Stack>
                                <FormLabel>Select from default documents</FormLabel>
                                <Controller
                                    name="defaultDocuments"
                                    control={formMethods.control}
                                    render={({ field, fieldState }) => (
                                        <Stack>
                                            {defaultDocuments.map((doc, index) => (
                                                <FormControlLabel
                                                    key={doc.id}
                                                    label={
                                                        <FileLabel
                                                            name={doc.name}
                                                            link={doc.file}
                                                        />
                                                    }
                                                    sx={{
                                                        padding: index === 0 ? 'default' : 0,
                                                    }}
                                                    control={
                                                        <Checkbox
                                                            checked={field.value.includes(doc.id)}
                                                            onChange={(e) => {
                                                                const newValue = e.target.checked
                                                                    ? [...field.value, doc.id]
                                                                    : field.value.filter(
                                                                          (id) => id !== doc.id
                                                                      );
                                                                field.onChange(newValue);
                                                            }}
                                                        />
                                                    }
                                                />
                                            ))}
                                            {fieldState.error && (
                                                <FormHelperText error>
                                                    {fieldState.error.message}
                                                </FormHelperText>
                                            )}
                                        </Stack>
                                    )}
                                />
                            </Stack>
                        ) : null}

                        <Stack>
                            <DragAndDropFileField
                                name="customDocuments"
                                label="Upload documents"
                                multiple={true}
                            />
                        </Stack>
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button color="inherit" onClick={onClose}>
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        loading={formMethods.formState.isSubmitting}
                    >
                        Share
                    </LoadingButton>
                </DialogActions>
            </FormContainer>
        </Dialog>
    );
}

export default UploadCorrespondenceDialog;
