import { Alert, Collapse, Link } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { WorkspaceDetail } from '../../../../api/workspace';

function DuplicateDeathCertificateAlert({ workspace }: { workspace: WorkspaceDetail }) {
    return (
        <Collapse in={!!workspace.workspace_with_duplicate_death_cert}>
            <Alert severity="warning" sx={{ mt: 2 }}>
                Death Certificate duplicated from Workspace{' '}
                <Link
                    component={RouterLink}
                    to={`/workspace/${workspace?.workspace_with_duplicate_death_cert?.external_id}`}
                    target="_blank"
                    color="inherit"
                >
                    eXc {workspace.workspace_with_duplicate_death_cert?.external_id}
                </Link>
                . If unexpected or inaccessible, please review our{' '}
                <Link
                    component={RouterLink}
                    to="https://customer-hub.estatexchange.com.au/"
                    target="_blank"
                    color="inherit"
                >
                    Support
                </Link>{' '}
                page for next steps.
            </Alert>
        </Collapse>
    );
}

export default DuplicateDeathCertificateAlert;
