import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { IconDownload } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import invariant from 'tiny-invariant';
import {
    estateFinalisationTaskQuery,
    updateEstateFinalisationTask,
    uploadDefaultEstateFinalisationDocumentsMutation,
} from '../../../api/estateFinalisation';
import { deleteGenericDocumentMutation } from '../../../api/genericDocument';
import { queryClient } from '../../../api/queryClient';
import { workspaceMembershipQuery } from '../../../api/workspaceMembership';
import CompleteTaskButton from '../../../components/CompleteTaskButton';
import Placeholder from '../../../components/Placeholder';
import QueryProgress from '../../../components/QueryProgress';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import { default as UploadCorrespondenceDialog } from './UploadAccountFinalisationDialog';

function AccountFinalisation() {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const task = workspace.tasks.estate_finalisation;
    invariant(task);

    const query = useQuery(estateFinalisationTaskQuery(task));
    const updateMutation = useMutation(updateEstateFinalisationTask(task));
    const deleteMutation = useMutation(deleteGenericDocumentMutation());

    const handleToggleSharedOffPlatform = (value: boolean) => {
        updateMutation.mutate({
            shared_off_platform: value,
        });
    };

    const handleComplete = () => {
        updateMutation.mutate(
            { status: 'completed' },
            {
                onSuccess: () => {
                    enqueueSnackbar('Account Finalisation accepted.', {
                        variant: 'success',
                    });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleUndoComplete = () => {
        updateMutation.mutate(
            { status: 'ready_for_action' },
            {
                onSuccess: () => {
                    enqueueSnackbar('Action undone.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleDelete = (documentId: string) => {
        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                queryClient.invalidateQueries(estateFinalisationTaskQuery(task));
            },
        });
    };

    const uploadDefaultDocsMutation = useMutation(
        uploadDefaultEstateFinalisationDocumentsMutation(task)
    );

    const showUploadDialog = () => {
        showModal(UploadCorrespondenceDialog, {
            defaultDocuments: query.data?.default_documents || [],
            taskType: 'estate_finalisation',
            title: 'Upload Account Finalisation documents',
            uploadDefaultDocuments: (documentIds: string[]) =>
                uploadDefaultDocsMutation.mutateAsync(documentIds),
            onSuccess: () => {
                queryClient.invalidateQueries(estateFinalisationTaskQuery(task));
            },
        });
    };

    return (
        <PageLayout
            title="Account Finalisation"
            actions={
                <Stack direction="row" alignItems="center" gap={2}>
                    {query.data && query.data.other_documents?.length > 0 ? (
                        <Button
                            startIcon={<IconDownload />}
                            variant="outlined"
                            color="inherit"
                            onClick={showUploadDialog}
                        >
                            Upload documents
                        </Button>
                    ) : null}

                    <CompleteTaskButton
                        completed={query.data?.status === 'completed'}
                        onComplete={handleComplete}
                        onUndo={handleUndoComplete}
                        sx={{ pl: 2 }}
                    />
                </Stack>
            }
        >
            <QueryProgress query={query}>
                <Stack gap={3} sx={{ mt: 3, flexGrow: 1, height: '100%' }}>
                    <DocumentsTable
                        sx={{ flexGrow: 1, height: '100%' }}
                        documents={Array.from(query.data?.other_documents || [])}
                        onDelete={handleDelete}
                        placeholder={
                            <Placeholder title="No documents shared yet">
                                <Button
                                    startIcon={<IconDownload />}
                                    color="inherit"
                                    variant="outlined"
                                    onClick={showUploadDialog}
                                >
                                    Upload documents
                                </Button>
                            </Placeholder>
                        }
                    />

                    <FormControlLabel
                        sx={{ pt: 0 }}
                        control={
                            <Checkbox
                                checked={query.data?.shared_off_platform || false}
                                onChange={(e) => {
                                    handleToggleSharedOffPlatform(e.target.checked);
                                }}
                            />
                        }
                        label="One or more documents were shared outside of estateXchange"
                    />
                </Stack>
            </QueryProgress>
        </PageLayout>
    );
}

export default AccountFinalisation;
