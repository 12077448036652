import { Button, Checkbox, FormControlLabel, Stack } from '@mui/material';
import { IconDownload } from '@tabler/icons-react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { enqueueSnackbar } from 'notistack';
import invariant from 'tiny-invariant';
import { deleteGenericDocumentMutation } from '../../../api/genericDocument';
import { queryClient } from '../../../api/queryClient';
import {
    statementOfPositionTaskQuery,
    updateStatementOfPositionTask,
    uploadDefaultStatementOfPositionDocumentsMutation,
} from '../../../api/statementOfPosition';
import { workspaceMembershipQuery } from '../../../api/workspaceMembership';
import CompleteTaskButton from '../../../components/CompleteTaskButton';
import LoadingArea from '../../../components/LoadingArea';
import Placeholder from '../../../components/Placeholder';
import QueryProgress from '../../../components/QueryProgress';
import DocumentsTable from '../../../components/documentsTable/DocumentsTable';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import { default as UploadCorrespondenceDialog } from './UploadAccountFinalisationDialog';

function StatementOfPosition() {
    const workspace = useWorkspace();
    const { showModal } = useModal();

    const sopTask = workspace.tasks.statement_of_position;
    invariant(sopTask);

    const query = useQuery(statementOfPositionTaskQuery(sopTask));

    const updateMutation = useMutation(updateStatementOfPositionTask(sopTask));

    const handleToggleSharedOffPlatform = (value: boolean) => {
        updateMutation.mutate({
            shared_off_platform: value,
        });
    };

    const handleMarkComplete = () => {
        updateMutation.mutate(
            { status: 'completed' },
            {
                onSuccess: () => {
                    enqueueSnackbar('Statement of Position accepted.', {
                        variant: 'success',
                        autoHideDuration: 4000,
                    });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const handleUndoComplete = () => {
        updateMutation.mutate(
            { status: 'ready_for_action' },
            {
                onSuccess: () => {
                    enqueueSnackbar('Action undone.', { variant: 'success' });
                    queryClient.invalidateQueries(
                        workspaceMembershipQuery(workspace.memberships[0])
                    );
                },
            }
        );
    };

    const deleteMutation = useMutation(deleteGenericDocumentMutation());

    const handleDelete = (documentId: string) => {
        deleteMutation.mutate(documentId, {
            onSuccess: () => {
                queryClient.invalidateQueries(statementOfPositionTaskQuery(sopTask));
            },
        });
    };

    const uploadDefaultDocsMutation = useMutation(
        uploadDefaultStatementOfPositionDocumentsMutation(sopTask)
    );

    const showUploadDialog = () => {
        showModal(UploadCorrespondenceDialog, {
            defaultDocuments: query.data?.default_documents || [],
            taskType: 'statement_of_position',
            title: 'Upload Statement of Position documents',
            uploadDefaultDocuments: (documentIds: string[]) =>
                uploadDefaultDocsMutation.mutateAsync(documentIds),
            onSuccess: () => {
                queryClient.invalidateQueries(statementOfPositionTaskQuery(sopTask));
            },
        });
    };

    if (!query.data) return <LoadingArea />;

    return (
        <PageLayout
            title="Statement of Position"
            actions={
                <Stack direction="row" alignItems="center" gap={2}>
                    {query.data && query.data.other_documents?.length > 0 ? (
                        <Button
                            startIcon={<IconDownload />}
                            color="inherit"
                            variant="outlined"
                            onClick={showUploadDialog}
                        >
                            Upload documents
                        </Button>
                    ) : null}

                    <CompleteTaskButton
                        completed={query.data?.status === 'completed'}
                        onComplete={handleMarkComplete}
                        onUndo={handleUndoComplete}
                        sx={{ pl: 2 }}
                    />
                </Stack>
            }
        >
            <QueryProgress query={query}>
                <Stack gap={3} sx={{ mt: 3, flexGrow: 1, height: '100%' }}>
                    <DocumentsTable
                        sx={{ flexGrow: 1, height: '100%' }}
                        documents={Array.from(query.data?.other_documents || [])}
                        onDelete={handleDelete}
                        placeholder={
                            <Placeholder title="No documents shared yet">
                                <Button
                                    startIcon={<IconDownload />}
                                    color="inherit"
                                    variant="outlined"
                                    onClick={showUploadDialog}
                                >
                                    Upload documents
                                </Button>
                            </Placeholder>
                        }
                    />

                    <FormControlLabel
                        sx={{ pt: 0 }}
                        control={
                            <Checkbox
                                checked={query.data?.shared_off_platform || false}
                                onChange={(e) => {
                                    handleToggleSharedOffPlatform(e.target.checked);
                                }}
                            />
                        }
                        label="One or more documents were shared outside of estateXchange"
                    />
                </Stack>
            </QueryProgress>
        </PageLayout>
    );
}

export default StatementOfPosition;
