import { Button, Stack } from '@mui/material';
import { IconCheckbox } from '@tabler/icons-react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Notification, notificationsQuery } from '../../../api/notification';
import Placeholder from '../../../components/Placeholder';
import SearchParamSelect from '../../../components/SearchParamSelect';
import UpdateBadge from '../../../components/UpdateBadge';
import GenericTable, { TableTab } from '../../../components/table/genericTable';
import useUser from '../../../hooks/useUser';
import { isWorkspaceOwner } from '../../../utils';
import PageLayout from '../PageLayout';
import { useWorkspace } from '../workspaceContext';
import ClearAllUpdatesDialog from './ClearAllUpdatesDialog';
import { getUpdateColumns } from './getUpdateColumns';

function Updates() {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { showModal } = useModal();

    const search = searchParams.get('search') || '';
    const tab = searchParams.get('tab') || 'new';
    const triggeredBy = searchParams.get('triggered_by') || undefined;
    const sortParam = searchParams.get('sort') || undefined;

    const workspace = useWorkspace();
    const user = useUser();
    const isParticipant = !isWorkspaceOwner(user);

    const columns = getUpdateColumns(isParticipant);

    const query = useInfiniteQuery({
        ...notificationsQuery({
            workspace: workspace.id,
            is_read: tab === 'cleared' ? 'true' : 'false',
            triggered_by: triggeredBy,
            search: search,
            ordering: sortParam,
        }),
        placeholderData: (previousData, _) => previousData,
    });

    const newUpdatesQuery = useInfiniteQuery(
        notificationsQuery({
            workspace: workspace.id,
            is_read: 'false',
        })
    );

    const newCount = newUpdatesQuery.data?.pages[0].count || 0;
    const notifications = query.data?.pages.flatMap((page) => page.results) || [];

    const filterByParticipantOptions = notifications
        .map((n) => ({
            id: n.triggered_by,
            label: n.triggered_by_organisation_name,
        }))
        .filter((value, index, self) => index === self.findIndex((t) => t.id === value.id));

    const tabs: TableTab[] = [
        {
            title: 'New',
            value: 'new',
            icon: (
                <UpdateBadge
                    count={newCount}
                    sx={{
                        '.Mui-selected &': {
                            bgcolor: 'action.selected',
                            color: 'text.primary',
                        },
                    }}
                />
            ),
        },
        { title: 'Cleared', value: 'cleared' },
    ];

    return (
        <PageLayout title="Updates">
            <Stack flexGrow={1} mt={2}>
                <GenericTable<Notification>
                    defaultSort="-created_at"
                    objects={notifications}
                    columns={columns}
                    placeholder={<Placeholder title="No Updates yet" />}
                    tabs={tabs}
                    currentTab={tab}
                    showLoadSpinner={query.isRefetching}
                    onRowClick={(notification) => {
                        const path = notification.link?.replace(/^https?:\/\/[^/]+/, '');
                        if (path) {
                            navigate(path);
                        }
                    }}
                    enableSearch
                    actions={
                        !isParticipant ? (
                            <SearchParamSelect
                                parameter="triggered_by"
                                placeholder="Filter by Participant"
                                options={filterByParticipantOptions}
                            />
                        ) : null
                    }
                    TableProps={{
                        sx: {
                            tableLayout: 'fixed',
                            whiteSpace: 'nowrap',

                            '& td': {
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            },
                        },
                    }}
                />

                {tab === 'new' && notifications.length > 0 && (
                    <Stack
                        direction="row"
                        gap={1}
                        alignItems="center"
                        justifyContent="center"
                        sx={{ mt: 5 }}
                    >
                        <Button
                            variant="outlined"
                            color="inherit"
                            startIcon={<IconCheckbox size={22} />}
                            onClick={() =>
                                showModal(ClearAllUpdatesDialog, {
                                    workspaceId: workspace.id,
                                })
                            }
                        >
                            Clear all Updates
                        </Button>
                    </Stack>
                )}
            </Stack>
        </PageLayout>
    );
}

export default Updates;
