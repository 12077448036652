import { Button, FormHelperText, FormLabel, Stack } from '@mui/material';
import { useUpdateEffect } from '@react-hookz/web';
import { useMutation } from '@tanstack/react-query';
import { useModal } from 'mui-modal-provider';
import { Controller, useFormContext } from 'react-hook-form-mui';
import { deleteGenericDocumentMutation } from '../../../../api/genericDocument';
import { queryClient } from '../../../../api/queryClient';
import { WorkspaceDetail, workspaceQuery } from '../../../../api/workspace';
import UploadGenericDocumentDialog from '../../../../components/UploadGenericDocumentDialog';
import DocumentsTable from '../../../../components/documentsTable/DocumentsTable';
import { DocumentTableRowType } from '../../../../components/documentsTable/DocumentsTableCells';
import RadioButtonGroupField from '../../../../components/fields/RadioButtonGroupField';
import { ValidationMessages } from '../../../../validationRules';

export interface FormValues {
    other_proof_of_death_held: string;
    other_proof_of_death: string;
}

interface OtherProofOfDeathFieldsProps {
    workspace: WorkspaceDetail;
    required: boolean;
}

function OtherProofofDeathFields({ workspace, required }: OtherProofOfDeathFieldsProps) {
    const { watch } = useFormContext<FormValues>();
    const otherProofHeld = watch('other_proof_of_death_held');

    if (required) {
        return (
            <Stack gap={2}>
                <FormLabel>Proof of Death</FormLabel>
                <UploadOtherProofOfDeathButton
                    workspace={workspace}
                    label="Upload Proof of Death"
                />
                <OtherProofOfDeathTable workspace={workspace} />
            </Stack>
        );
    }

    return (
        <Stack spacing={2}>
            <RadioButtonGroupField
                name="other_proof_of_death_held"
                label="Is other Proof of Death held?"
                required
                options={[
                    { label: 'Yes', id: 1, value: 'yes' },
                    { label: 'No', id: 2, value: 'no' },
                ]}
                valueKey="value"
                infoTooltip="Include here any other independent documents which may be considered a Proof of Death."
            />
            {otherProofHeld === 'yes' && (
                <>
                    <UploadOtherProofOfDeathButton workspace={workspace} />
                    <OtherProofOfDeathTable workspace={workspace} />
                </>
            )}
        </Stack>
    );
}

const UploadOtherProofOfDeathButton = ({
    workspace,
    label,
}: {
    workspace: WorkspaceDetail;
    label?: string;
}) => {
    const { showModal } = useModal();
    const { trigger, control } = useFormContext<FormValues>();

    // Reevaluate validation when documents change
    useUpdateEffect(() => {
        trigger('other_proof_of_death');
    }, [workspace.other_pod_docs.length, workspace.death_certificate]);

    return (
        <Controller
            control={control}
            name="other_proof_of_death"
            rules={{
                validate: () => {
                    return workspace.other_pod_docs.length > 0 || ValidationMessages.REQUIRED_FILE;
                },
            }}
            render={({ fieldState: { error } }) => (
                <Stack gap={1} alignItems="flex-start">
                    <Button
                        variant="outlined"
                        color="inherit"
                        onClick={() =>
                            showModal(UploadGenericDocumentDialog, {
                                title: 'Upload Proof of Death',
                                taskType: 'proof_of_death',
                            })
                        }
                    >
                        {label || 'Upload other Proof of Death'}
                    </Button>

                    {error && <FormHelperText error>{error.message}</FormHelperText>}
                </Stack>
            )}
        />
    );
};

const OtherProofOfDeathTable = ({ workspace }: { workspace: WorkspaceDetail }) => {
    const deleteMutation = useMutation(deleteGenericDocumentMutation());

    const handleDelete = async (id: string) => {
        try {
            await deleteMutation.mutateAsync(id);
            queryClient.invalidateQueries(workspaceQuery(workspace.id));
        } catch (error) {
            console.error('Failed to delete document:', error);
        }
    };

    if (workspace.other_pod_docs.length === 0) {
        return null;
    }

    return (
        <DocumentsTable
            documents={workspace.other_pod_docs}
            onDelete={handleDelete}
            extraColumns={[
                {
                    id: 'type',
                    label: 'Type',
                    Component: DocumentTableRowType,
                },
            ]}
        />
    );
};

export default OtherProofofDeathFields;
