import { Button, Stack } from '@mui/material';
import { IconAlertCircle } from '@tabler/icons-react';
import { Form } from 'react-router-dom';
import { appSettings } from '../../appSettings';
import Placeholder from '../../components/Placeholder';

function AdminUserError() {
    const { auth0Enabled } = appSettings;

    return (
        <Stack height="100vh" justifyContent="center" alignItems="center">
            <Placeholder
                IconComponent={IconAlertCircle}
                title="Admin Users are not allowed to use the application"
                description={
                    <Form
                        method={auth0Enabled ? 'get' : 'post'}
                        action={auth0Enabled ? '/api/auth/auth0_logout/' : '/logout'}
                        reloadDocument={auth0Enabled}
                    >
                        <Button variant="inverse" type="submit">
                            Logout
                        </Button>
                    </Form>
                }
            />
        </Stack>
    );
}

export default AdminUserError;
