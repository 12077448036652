import { Alert, AlertColor } from '@mui/material';
import {
    SnackbarProvider as BaseSnackbarProvider,
    CustomContentProps,
    SnackbarProviderProps,
} from 'notistack';
import React, { forwardRef, memo } from 'react';

const CustomAlert = memo(
    forwardRef<HTMLDivElement, CustomContentProps & { component: React.ReactNode }>(
        ({ id, message, variant, component, action }, forwardedRef) => {
            return (
                <Alert
                    ref={forwardedRef}
                    elevation={1}
                    severity={variant as AlertColor}
                    sx={{
                        '&.MuiAlert-filled': {
                            backgroundColor: (theme) => {
                                return variant === 'success'
                                    ? theme.palette[variant].main
                                    : theme.palette[variant as AlertColor].light;
                            },
                        },
                    }}
                    variant="filled"
                    action={typeof action === 'function' ? action(id) : action}
                >
                    {component || message}
                </Alert>
            );
        }
    )
);

export function SnackbarProvider(props: SnackbarProviderProps) {
    return (
        <BaseSnackbarProvider
            maxSnack={3}
            preventDuplicate
            hideIconVariant
            Components={{
                default: CustomAlert,
                info: CustomAlert,
                success: CustomAlert,
                error: CustomAlert,
                warning: CustomAlert,
            }}
            {...props}
        />
    );
}
