// Copyright (C) 2024, Affinda and estateXchange

import {
    CssVarsPalette,
    CssVarsThemeOptions,
    darken,
    experimental_extendTheme as extendTheme,
    lighten,
    Palette,
    Slide,
    Theme,
} from '@mui/material';
import {
    IconAlertCircle,
    IconAlertTriangle,
    IconCheck,
    IconChevronDown,
    IconInfoCircle,
} from '@tabler/icons-react';
import colorSchemes, { colors } from './palette';
import typography from './typography';

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        inverse: true;
    }
}

const listItemStyleOverrides = {
    root: ({ theme }: { theme: Theme }) => ({
        whiteSpace: 'nowrap',
        '&.Mui-selected': {
            color: theme.palette.text.primary,
            '& .MuiListItemIcon-root': { color: 'inherit' },
            '&, &:hover': {
                backgroundColor: colors.sand,
            },
        },
    }),
};

export const cssVarsThemeOptions: CssVarsThemeOptions = {
    colorSchemes: colorSchemes,
    typography: typography,
    shape: {
        borderRadius: 8,
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: (theme) => ({
                '& .tabler-icon': {
                    strokeWidth: 1.5,
                },
            }),
        },
        MuiContainer: {
            styleOverrides: {
                root: ({ theme }) => ({
                    paddingInline: theme.spacing(4) + ' !important',
                }),
            },
        },

        MuiPaper: {
            styleOverrides: {
                root: { backgroundImage: 'none' },
            },
            defaultProps: {
                elevation: 0,
            },
        },
        MuiList: {
            defaultProps: {
                disablePadding: true,
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    paddingBottom: theme.spacing(1),
                }),
            },
        },
        MuiListItem: {
            styleOverrides: listItemStyleOverrides,
        },
        MuiListItemButton: {
            styleOverrides: listItemStyleOverrides,
        },
        MuiListItemIcon: {
            styleOverrides: {
                root: ({ theme }) => ({
                    minWidth: '32px !important',
                    color: theme.palette.text.disabled,
                }),
            },
        },
        MuiDialog: {
            styleOverrides: {
                paper: ({ theme }) => ({
                    borderRadius: '30px',
                    padding: theme.spacing(3),
                }),
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...theme.typography.h3,
                    padding: theme.spacing(3),
                }),
            },
        },
        MuiDialogContent: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: theme.spacing(3),
                    paddingTop: 0,
                }),
            },
        },
        MuiDialogActions: {
            styleOverrides: {
                root: ({ theme }) => ({
                    padding: theme.spacing(3),
                    paddingTop: 0,
                }),
            },
        },

        MuiDrawer: {
            styleOverrides: {
                paper: ({ theme }) => ({ padding: theme.spacing(3) }),
            },
        },

        MuiListItemText: {
            styleOverrides: {
                root: ({ theme }) => ({
                    margin: 0,
                }),
                primary: ({ theme }) => ({ ...theme.typography.body1 }),
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: ({ theme }) => ({
                    ...theme.typography.body1,
                    fontWeight: theme.typography.fontWeightBold,
                    color: theme.palette.text.primary,
                    whiteSpace: 'nowrap',
                    // textTransform: 'uppercase',
                    background: 'transparent',
                    paddingTop: theme.spacing(3),
                    paddingBottom: theme.spacing(1),
                }),
                gutters: ({ theme }) => ({
                    paddingLeft: theme.spacing(2),
                    paddingRight: theme.spacing(2),
                }),
            },
        },
        MuiMenu: {
            styleOverrides: {
                list: ({ theme }) => ({
                    padding: 0,
                    backgroundColor: colors.snowLight,
                }),
            },
            defaultProps: {
                slotProps: {
                    paper: {
                        elevation: 1,
                        sx: {
                            borderRadius: 0,
                        },
                    },
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: ({ theme }: { theme: Theme }) => ({
                    paddingTop: 12,
                    paddingBottom: 12,
                    backgroundColor: colors.snowLight,
                    '&:hover': {
                        backgroundColor: colors.sand,
                    },
                    '&:focus': {
                        backgroundColor: colors.sand,
                    },
                    '&.Mui-selected': {
                        '&, &:hover': {
                            backgroundColor: colors.sand,
                        },
                        '&, &:focus': {
                            backgroundColor: colors.sand,
                        },
                    },
                }),
            },
        },
        MuiSelect: {
            defaultProps: {
                IconComponent: IconChevronDown,
                variant: 'filled',
            },
            styleOverrides: {
                icon: {
                    strokeWidth: 1.5,
                },
            },
        },
        MuiAutocomplete: {
            defaultProps: {
                popupIcon: <IconChevronDown />,
                componentsProps: {
                    paper: {
                        elevation: 1,
                        sx: {
                            borderRadius: 0,
                        },
                    },
                },
            },
            styleOverrides: {
                listbox: ({ theme }) => ({
                    backgroundColor: theme.palette.background.default,
                }),
                option: ({ theme }) => ({
                    backgroundColor: theme.palette.background.default,
                    '&:hover': {
                        backgroundColor: colors.sand,
                    },
                    '&:focus': {
                        backgroundColor: colors.sand,
                    },
                    '&.Mui-selected': {
                        '&, &:hover': {
                            backgroundColor: colors.sand,
                        },
                        '&, &:focus': {
                            backgroundColor: colors.sand,
                        },
                    },
                }),
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
                sx: { whiteSpace: 'nowrap' },
            },
            styleOverrides: {
                startIcon: { marginRight: 6 },
                sizeMedium: {
                    lineHeight: 2.5,
                    paddingInline: 20,
                },
                sizeLarge: {
                    fontSize: '1.125rem',
                    lineHeight: 2.5,
                    paddingInline: 30,
                },
                root: {
                    borderRadius: 100,
                },
                outlined: { borderWidth: 2 },
                containedSizeSmall: { paddingInline: '12px' },
            },
            variants: [
                {
                    props: { variant: 'inverse' },
                    style: {
                        color: colors.white,
                        backgroundColor: colors.charcoal,
                        '&:hover': {
                            color: colors.turquoiseUp,
                            backgroundColor: colors.charcoal,
                        },
                        '&:focus': {
                            backgroundColor: colors.charcoal,
                            color: colors.turquoiseUp,
                        },
                        '&.Mui-disabled': {
                            color: colors.white,
                        },
                    },
                },
                {
                    props: { variant: 'outlined' },
                    style: {
                        '&:hover': {
                            borderColor: colors.turquoiseUp,
                            backgroundColor: colors.snow,
                        },
                        '&:focus': {
                            borderColor: colors.turquoiseUp,
                            backgroundColor: colors.snow,
                        },
                    },
                },
            ],
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    color: 'inherit',
                    opacity: 0.5,
                },
            },
        },
        MuiSnackbar: {
            defaultProps: {
                TransitionComponent: (props) => <Slide {...props} direction="up" />,
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'filled',
                helperText: ' ',
            },
        },
        MuiInputLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.text.primary,
                    opacity: 1,
                    '&.Mui-focused': { color: theme.palette.text.primary },
                }),
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    color: theme.palette.text.primary,
                    opacity: 1,
                    '&.Mui-focused': { color: theme.palette.text.primary },
                }),
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: ({ theme }) => ({
                    position: 'relative',
                }),
            },
        },
        MuiFilledInput: {
            styleOverrides: {
                root: ({ theme }) => ({
                    backgroundColor: theme.palette.background.default,

                    '&:hover': {
                        backgroundColor: colors.sand,
                    },

                    '&.Mui-disabled': {
                        color: theme.palette.text.disabled,
                        backgroundColor: colors.snow,
                    },

                    '&.Mui-focused': { backgroundColor: colors.sand },
                    '&:after': {
                        borderColor: theme.palette.text.primary,
                    },

                    '& input[type=file]': {
                        cursor: 'pointer',
                        paddingLeft: '8px',
                        display: 'flex',
                        fontSize: '14px',
                        fontWeight: 300,
                        alignItems: 'center',
                        '&::file-selector-button': {
                            background: colors.charcoal,
                            border: 0,
                            color: 'var(--mui-palette-background-paper)',
                            font: 'inherit',
                            margin: 0,
                            marginRight: '.7em',
                            marginBottom: '.2em',
                            padding: '.05em 1em',
                            borderRadius: '99px',
                            width: 'auto',
                            pointerEvents: 'none',
                        },
                        '&:hover::file-selector-button': {
                            color: 'var(--mui-palette-primary-dark)',
                        },
                    },
                }),
            },
        },
        MuiCircularProgress: {
            defaultProps: {
                thickness: 5,
                disableShrink: true,
            },
            styleOverrides: {
                circle: { strokeLinecap: 'round' },
            },
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: ({ theme }) => ({ borderRadius: theme.shape.borderRadius }),
            },
        },
        MuiLink: {
            defaultProps: {
                color: 'text.primary',
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiTabs-indicator': {
                        display: 'none',
                    },

                    '&.underlined': {
                        minHeight: 40,

                        '& .MuiTabs-indicator': {
                            display: 'flex',
                            justifyContent: 'center',
                            backgroundColor: 'transparent',
                            height: 2,
                            '&::before': {
                                content: '""',
                                width: '100%',
                                backgroundColor: colors.charcoal,
                            },
                        },
                    },
                }),
            },
        },
        MuiTab: {
            styleOverrides: {
                root: ({ theme }) => ({
                    textTransform: 'none',
                    padding: `${theme.spacing(1)} ${theme.spacing(1.75)}`,
                    minWidth: 0,
                    minHeight: 0,
                    letterSpacing: 0,
                    borderRadius: 100,
                    '&:hover': {
                        backgroundColor: theme.palette.action.hover,
                    },
                    '&.Mui-selected': {
                        color: theme.palette.text.primary,
                        backgroundColor: `rgb(var(--mui-palette-primary-mainChannel) / 0.8)`,
                    },

                    '&.underlined': {
                        ...theme.typography.body1,

                        padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
                        borderRadius: 0,
                        '&.MuiButtonBase-root': {
                            margin: 0,
                        },
                        '&.Mui-selected': {
                            color: theme.palette.text.primary,
                            backgroundColor: theme.palette.background.paper,
                        },
                    },
                }),
            },
        },
        MuiChip: {
            styleOverrides: {
                root: ({ theme, ownerState }) => {
                    let colorKey: keyof (Palette & CssVarsPalette) | undefined;
                    if (ownerState.color === 'default' || ownerState.color === undefined) {
                        colorKey = undefined;
                    } else {
                        colorKey = ownerState.color;
                    }
                    const color = colorKey
                        ? getComputedStyle(document.documentElement).getPropertyValue(
                              `--mui-palette-${colorKey}-main`
                          )
                        : colors.snow;

                    return {
                        backgroundColor: colorKey
                            ? `rgb(var(--mui-palette-${colorKey}-lightChannel) / 0.6)`
                            : colors.snow,
                        color: color ? darken(color, 0.6) : undefined,
                        border: 0,
                        fontWeight: theme.typography.fontWeightMedium,
                        borderRadius: 2,
                        '&.MuiChip-clickable:hover': {
                            backgroundColor: colorKey
                                ? `rgb(var(--mui-palette-${colorKey}-lightChannel))`
                                : colors.sand,
                        },
                    };
                },
            },
        },
        MuiSlider: {
            styleOverrides: {
                track: { border: 0 },
                thumb: { '&::before': { boxShadow: 'none' } },
            },
        },
        MuiTypography: {
            defaultProps: {
                sx: { wordWrap: 'break-word' },
                variantMapping: {
                    body3: 'div',
                },
            },
        },
        MuiAlert: {
            defaultProps: {
                variant: 'filled',
                iconMapping: {
                    success: <IconCheck />,
                    info: <IconInfoCircle size={26} />,
                    warning: <IconAlertTriangle />,
                    error: <IconAlertCircle />,
                },
            },
            styleOverrides: {
                root: ({ theme, ownerState }) => {
                    const color = ownerState.severity
                        ? getComputedStyle(document.documentElement).getPropertyValue(
                              `--mui-palette-${ownerState.severity}-main`
                          )
                        : colors.snow;

                    const light = ownerState.severity
                        ? getComputedStyle(document.documentElement).getPropertyValue(
                              `--mui-palette-${ownerState.severity}-light`
                          )
                        : colors.snow;

                    return {
                        backgroundColor: lighten(light, 0.6),
                        color: darken(color, 0.6),
                        padding: theme.spacing(1.5),
                        paddingRight: theme.spacing(3.5),
                        paddingLeft: theme.spacing(3.5),
                        alignItems: 'center',
                        borderRadius: '40px',

                        '& .MuiAlert-icon': {
                            color: color ? darken(color, 0.5) : undefined,
                        },
                    };
                },
            },
        },
        MuiTooltip: {
            defaultProps: {
                arrow: true,
            },
            styleOverrides: {
                tooltip: ({ theme }) => ({
                    ...theme.typography.body3,
                    lineHeight: 1.3,
                    paddingBlock: theme.spacing(0.6),
                    paddingInline: theme.spacing(1.2),
                }),
            },
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    // A checked checkbox is just an icon, and since our primary
                    // color is light, there's no easy way to change the color
                    // of the actual tick. Here's a bit of trickery to add the
                    // color as a pseudoelement beneath the checkbox icon.
                    '&.Mui-checked:not(.Mui-disabled)': {
                        position: 'relative',
                        color: 'inherit',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            inset: '14px',
                        },
                        '& svg': {
                            position: 'relative',
                        },
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: ({ theme }) => ({
                    // marginLeft: theme.spacing(-0.5),
                    // marginRight: theme.spacing(-0.5),
                    paddingBottom: theme.spacing(0),
                    paddingTop: theme.spacing(1),

                    '& .MuiFormControlLabel-label': {
                        ...typography.body2,
                        lineHeight: '100%',
                    },
                }),
            },
        },
        MuiRadio: {
            defaultProps: {
                size: 'small',
            },
            styleOverrides: {
                root: ({ theme }) => ({
                    marginRight: theme.spacing(0),
                    paddingBottom: theme.spacing(0.75),
                    paddingTop: theme.spacing(0.75),

                    '&.Mui-checked:not(.Mui-disabled)': {
                        color: colors.charcoal,
                    },
                }),
            },
        },
        MuiRadioGroup: {
            styleOverrides: {
                root: ({ theme }) => ({
                    // change the padding of the nested form label
                    // '& .MuiFormGroup-root': {
                    //     paddingTop: theme.spacing(12),
                    // },

                    // add padding to the first child
                    '& > :first-child': {
                        paddingTop: theme.spacing(2),
                    },
                }),
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: ({ theme }) => ({
                    borderColor: theme.palette.divider,

                    '.table-soft &': {
                        borderColor: colors.sand,
                    },
                }),
            },
        },
        MuiTableHead: {
            styleOverrides: {
                root: ({ theme }) => ({
                    '& .MuiTableCell-root': {
                        paddingBottom: theme.spacing(1),
                        paddingTop: theme.spacing(1),
                        backgroundColor: theme.palette.background.paper,

                        '.table-soft &': {
                            paddingBottom: theme.spacing(0.5),
                            paddingTop: theme.spacing(0.5),
                            backgroundColor: theme.palette.background.default,
                            borderTop: '1px solid',
                            borderTopColor: theme.palette.divider,
                            borderBottom: '1px solid',
                            borderBottomColor: colors.sand,
                        },
                    },
                }),
            },
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 28,
                    height: 16,
                    padding: 0,
                    margin: 8,
                    '& .MuiSwitch-switchBase': {
                        '&.Mui-checked': {
                            transform: 'translateX(12px)',
                        },
                    },
                },
                switchBase: {
                    p: 1,
                    '&.Mui-checked': {
                        transform: 'translateX(12px)',
                        '& + .MuiSwitch-track': {
                            backgroundColor: colors.turquoise,
                            opacity: 1,
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        width: 11,
                        height: 11,
                        transform: 'translateY(-1.5px) translateX(-1.5px)',
                    },
                },
                thumb: {
                    boxShadow: 'none',
                    backgroundColor: colors.white,
                },
                track: {
                    borderRadius: 26 / 2,
                    transition: 'backgroundColor 0.3s',
                },
            },
        },
    },
};

export const cssVarsTheme = extendTheme(cssVarsThemeOptions);
