import { CssBaseline, Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { QueryClientProvider } from '@tanstack/react-query';
import { enAU } from 'date-fns/locale';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { RouterProvider } from 'react-router-dom';
import { queryClient } from './api/queryClient';
import { appSettings } from './appSettings';
import ErrorBoundary from './components/ErrorBoundary';
import ProgressBar from './components/ProgressBar';
import { SnackbarProvider } from './components/SnackbarProvider';
import { router } from './router/router';
import { initSentry } from './sentry';
import { cssVarsTheme } from './theme/theme';

initSentry({
    dsn: appSettings?.sentryDSN,
    release: appSettings?.releaseVersion,
    environment: appSettings.sentryEnvironment,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <ErrorBoundary>
            <CssVarsProvider theme={cssVarsTheme} defaultMode="light">
                <CssBaseline enableColorScheme />
                <HelmetProvider>
                    {['development', 'staging'].includes(appSettings.sentryEnvironment) && (
                        <Helmet>
                            <meta name="robots" content="noindex, nofollow" />
                        </Helmet>
                    )}
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enAU}>
                        <SnackbarProvider>
                            <QueryClientProvider client={queryClient}>
                                <RouterProvider router={router} fallbackElement={<ProgressBar />} />
                            </QueryClientProvider>
                        </SnackbarProvider>
                    </LocalizationProvider>
                </HelmetProvider>
            </CssVarsProvider>
        </ErrorBoundary>
    </React.StrictMode>
);
