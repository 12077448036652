import { Button, TableCellProps } from '@mui/material';
import { IconArrowBackUp, IconCheckbox } from '@tabler/icons-react';
import { useMutation } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { Notification, updateNotificationMutation } from '../../../api/notification';
import TableRowControls from '../../../components/TableRowControls';

interface UpdateActionsProps extends TableCellProps {
    notification: Notification;
}

function UpdateActions({ notification, ...tableCellProps }: UpdateActionsProps) {
    const updateMutation = useMutation(updateNotificationMutation(notification.id));

    const handleClear = () => {
        updateMutation.mutateAsync(
            { is_read: true },
            {
                onSuccess: () => {
                    enqueueSnackbar('Notification cleared', { variant: 'success' });
                },
            }
        );
    };

    const handleUnclear = () => {
        updateMutation.mutateAsync(
            { is_read: false },
            {
                onSuccess: () => {
                    enqueueSnackbar('Notification restored', { variant: 'success' });
                },
            }
        );
    };

    return (
        <TableRowControls {...tableCellProps}>
            {!notification.is_read ? (
                <Button
                    size="small"
                    startIcon={<IconCheckbox size={20} />}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleClear();
                    }}
                    variant="outlined"
                    color="inherit"
                >
                    Clear
                </Button>
            ) : (
                <Button
                    size="small"
                    startIcon={<IconArrowBackUp size={20} />}
                    onClick={(e) => {
                        e.stopPropagation();
                        handleUnclear();
                    }}
                    variant="outlined"
                    color="inherit"
                >
                    Restore
                </Button>
            )}
        </TableRowControls>
    );
}

export default UpdateActions;
