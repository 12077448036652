import { RouteObject, redirect } from 'react-router-dom';
import { canViewStandaloneTools, canViewWorkspaces, fetchUser, isLoggedIn } from '../api/user';
import MainLayout from '../pages/main/MainLayout';
import Sidebar from '../pages/main/Sidebar';
import Workspaces from '../pages/main/Workspaces';
import DeathCertificateVerificationTool from '../pages/standaloneTools/DeathCertificateVerificationTool';
import FactOfDeathBulkTool from '../pages/standaloneTools/FactOfDeathBulkTool';
import FactOfDeathSingleTool from '../pages/standaloneTools/FactOfDeathSingleTool';
import GorRetrievalTool from '../pages/standaloneTools/GorRetrievalTool';
import StandaloneTools from '../pages/standaloneTools/StandaloneTools';
import StandaloneToolsLayout from '../pages/standaloneTools/StandaloneToolsLayout';
import VerificationOfIdentityTool from '../pages/standaloneTools/VerificationOfIdentityTool';
import PermissionGuard from './PermissionGuard';
import workspaceMemberRoutes from './workspaceMemberRoutes';
import workspaceRoutes from './workspaceRoutes';

const mainRoutes: RouteObject = {
    path: '/',
    element: <MainLayout />,
    loader: async () => {
        const user = await fetchUser();
        if (!isLoggedIn(user)) {
            localStorage.setItem('next', window.location.pathname + window.location.search);
            throw redirect('/auth/login');
        }
        if (user?.is_staff) {
            throw redirect('/admin-user');
        }
        return user;
    },
    children: [
        {
            path: '/',
            element: <Sidebar />,
            children: [
                {
                    path: '',
                    loader: async () => {
                        const user = await fetchUser();
                        if (!canViewWorkspaces(user) && canViewStandaloneTools(user)) {
                            throw redirect('/standalone-tools');
                        }
                        return null;
                    },
                    element: (
                        <PermissionGuard permission="workspaces">
                            <Workspaces />
                        </PermissionGuard>
                    ),
                },
                {
                    id: 'standalone-tools',
                    loader: async () => {
                        const user = await fetchUser();
                        if (!canViewStandaloneTools(user) && canViewWorkspaces(user)) {
                            throw redirect('/');
                        }
                        return null;
                    },
                    element: <StandaloneToolsLayout />,

                    path: '/standalone-tools',
                    children: [
                        {
                            path: '',
                            index: true,
                            element: <StandaloneTools />,
                        },
                        {
                            path: 'fact-of-lookup-single',
                            element: (
                                <PermissionGuard permission="death_check_single">
                                    <FactOfDeathSingleTool />
                                </PermissionGuard>
                            ),
                        },
                        {
                            path: 'fact-of-death-lookup-bulk',
                            element: (
                                <PermissionGuard permission="death_check_bulk">
                                    <FactOfDeathBulkTool />
                                </PermissionGuard>
                            ),
                        },
                        {
                            path: 'electronic-verification-of-identity',
                            element: (
                                <PermissionGuard permission="e_voi">
                                    <VerificationOfIdentityTool />
                                </PermissionGuard>
                            ),
                        },
                        {
                            path: 'death-certificate-verification',
                            element: (
                                <PermissionGuard permission="death_cert_verification">
                                    <DeathCertificateVerificationTool />
                                </PermissionGuard>
                            ),
                        },
                        {
                            path: 'victorian-grant-of-representation-lookup',
                            element: (
                                <PermissionGuard permission="gor_retrieval">
                                    <GorRetrievalTool />
                                </PermissionGuard>
                            ),
                        },
                    ],
                },
            ],
        },
        workspaceRoutes,
        workspaceMemberRoutes,
    ],
};

export default mainRoutes;
