import { Box, Stack, TableCell, Typography } from '@mui/material';
import { Notification } from '../../../api/notification';
import DateTimeString from '../../../components/DateTimeString';
import { Column } from '../../../components/table/genericTable';
import UpdateActions from './UpdateActions';
import { notificationIcon } from './utils';

export function getUpdateColumns(isParticipant: boolean): Column<Notification>[] {
    const columns: Column<Notification>[] = [
        {
            id: 'description',
            label: 'Description',
            sortable: 'description',
            sx: { width: '50%', minWidth: '50%' },
            Component: ({ object: notification }) => (
                <TableCell>
                    <Stack direction="row" gap={1} alignItems="center">
                        <Box
                            stroke={1.5}
                            component={notificationIcon[notification.type]}
                            size={22}
                            flexShrink={0}
                        />
                        <Typography
                            variant="body2"
                            sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            {notification.description}
                        </Typography>
                    </Stack>
                </TableCell>
            ),
        },
        {
            id: 'created_at',
            label: 'Date',
            sortable: 'created_at',
            sx: { width: '20%', minWidth: '20%' },
            Component: ({ object: notification }) => (
                <TableCell>{DateTimeString(notification.created_at)}</TableCell>
            ),
        },
    ];

    if (!isParticipant) {
        columns.push({
            id: 'triggered_by',
            label: 'Participant',
            sortable: 'triggered_by_organisation_name',
            sx: { width: '20%', minWidth: '20%' },
            Component: ({ object: notification }) => (
                <TableCell>{notification.triggered_by_organisation_name}</TableCell>
            ),
        });
    }

    columns.push({
        id: 'actions',
        sx: { width: '10%', minWidth: '10%' },
        Component: ({ object: notification }) => <UpdateActions notification={notification} />,
    });

    return columns;
}
